import React from "react";
import { NavLink } from "react-router-dom";
import Typewriter from "typewriter-effect";
import HeroImage from "./HeroImage";

const HeroHeader = () => {
  return (
    <header className="section-padding style-1" data-scroll-index="0">
      <div className="container">
        <div className="content">
          <div className="row">
            <div className="col-lg-6">
              <div className="info">
                <div className="section-head mb-60">
                  <h3 className="color-main text-uppercase text-3xl font-semibold">
                    Advskill
                  </h3>
                  <div className=" text-2xl sm:text-5xl mt-2 pr-2">
                    <h1>Vous propose</h1>
                    <span className="fw-normal p-2">
                      <Typewriter
                        options={{
                          strings: [
                            "Consultants IT",
                            "Cloud/Devops",
                            "Pilotage de projets",
                            "Cybersécurité",
                            "BI/ Big data",
                          ],
                          autoStart: true,
                          loop: true,
                        }}
                      />
                    </span>
                  </div>
                </div>
                <div className="text">
                  <p>
                    Nous accompagnons les entreprises dans tous les secteurs
                    offrant des solutions digitales répondant aux exigences
                    modernes.
                  </p>{" "}
                </div>
                <div className="mt-4">
                  <NavLink
                    className="navlink rounded p-3 "
                    to="/notre-expertise"
                  >
                    Nos services
                  </NavLink>
                </div>
              </div>
            </div>
            <div className="col-lg-5 offset-lg-1">
              <div className="img">
                {/* <img src="assets/img/header/head.png" alt="" /> */}
                <div>
                  <HeroImage />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <img
          src="assets/img/header/head_shape_r.png"
          alt=""
          className="head-shape-r wow"
        />
        <img
          src="assets/img/header/head_shape_l.png"
          alt=""
          className="head-shape-l wow"
        />
      </div>
    </header>
  );
};

export default HeroHeader;
