import React from "react";
import IlNousFontConfiance from "../../components/Apropos/IlNousFontConfiance";
import QuiSommesNous from "../../components/Apropos/QuiSommesNous";
import CequiNousDistingue from "../../components/Apropos/CequiNousDistingue";

const AproposDeNous = () => {
  return (
    <main class="about-page style-5">
      <div id="preloader"> </div>
      <QuiSommesNous />
      <IlNousFontConfiance />
      <CequiNousDistingue />
    </main>
  );
};

export default AproposDeNous;
