import React from "react";
import { NavLink } from "react-router-dom";
import Slider from "../../components/LogoSlider/Slider";

const PouquoiNousChoisir = () => {
  return (
    <div>
      <section className="about style-1" data-scroll-index="1">
        <div className="container">
          <div className="p-3 text-4xl sm:text-5xl ">
            <h1>Ils nous font confiance</h1>
          </div>
          <div className="content">
            <Slider />

            <div className="about-info">
              <div className="row justify-content-between">
                <div className="col-lg-5">
                  <div className="title">
                    <h3 className=" wow fadeInUp slow">
                      “Leur parole vaut mieux que la nôtre”
                    </h3>
                    {/* <small className=" wow fadeInUp slow fw-bold">Patricia Cross</small> */}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info m-3">
                    <p className=" wow fadeInUp slow text-lg ">
                      Nous pouvons vous assister que ce soit en transformation
                      digitale, en refonte ou modernisation d’infrastructure ou
                      résoudre tout problème spécifique à votre domaine.
                    </p>
                    <hr className="text-white " />
                    <p className=" wow fadeInUp slow">
                      Advskill est le partenaire idéal pour beaucoup
                      d'entreprises technologiques ou de service. Nous aidons
                      nos clients à se concentrer uniquement sur la valeur
                      ajoutée de leur activité, que ce soit par du développement
                      spécifique, de la conception, de l'architecture, des
                      services de consultants ou de QA.
                    </p>
                    <a className="btn btn-outline-light mt-5 sm-butn wow fadeInUp slow">
                      <span>
                        <NavLink to="/qui-sommes-nous">En savoir plus</NavLink>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="about-numbers">
              <div className="row">
                <div className="col-lg-3 text-lg">
                  <div className="num-item wow fadeInUp" data-wow-delay="0">
                    <div className="num">
                      <span className="counter">10</span>
                      <span>
                        <i className="fas fa-plus"></i>
                      </span>
                    </div>
                    <div className="inf">Années d'expérience</div>
                  </div>
                </div>
                <div className="col-lg-3 text-lg">
                  <div className="num-item wow fadeInUp" data-wow-delay="0.2s">
                    <div className="num">
                      <span>+</span>
                      <span className="counter">50</span>
                    </div>
                    <div className="inf">Projets livrés</div>
                  </div>
                </div>
                <div className="col-lg-3 text-lg">
                  <div className="num-item wow fadeInUp" data-wow-delay="0.4s">
                    <div className="num">
                      <span className="counter">200</span>
                    </div>
                    <div className="inf">Clients satisfaits</div>
                  </div>
                </div>
                <div className="col-lg-3 text-lg">
                  <div className="num-item wow fadeInUp" data-wow-delay="0.6s">
                    <div className="num">
                      <span className="counter">250</span>
                    </div>
                    <div className="inf">Missions réalisées</div>
                  </div>
                </div>
              </div>
            </div>
            <img
              src="assets/img/about/num_shap.png"
              alt=""
              className="about_shap"
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default PouquoiNousChoisir;
