import React from "react";

const NosServices = () => {
  return (
    <section className="services section-padding style-1" data-scroll-index="2">
      <div className="container">
        <div className="row">
          <div className="col offset-lg-1 ">
            <span className="text-4xl   mb-3 text-[#1900CC]">Nos services</span>
            <div className="section-head mb-60 text-base text-semi-bold ">
              <h2 className="text-2xl font-semibold fadeInUp">
                Des solutions parfaites{" "}
                <span className="fw-normal"> pour votre business</span>
              </h2>
            </div>
          </div>
        </div>
        <div className="content">
          <div className="row">
            <div className="col-lg-4">
              <div className="service-box mb-4 wow fadeInUp" data-wow-delay="0">
                <h5>
                  <a href="page-services-5.html"> Consultants IT </a>
                  <span className=" bg-[#1900CC] p-3 text-white  text-2xl rounded-full">
                    01
                  </span>
                </h5>
                <div className="icon">
                  <img src="assets/img/icons/serv_icons/1.png" alt="" />
                </div>
                <div className="info">
                  <div className="text">
                    <p>
                      Les consultants IT fournissent une expertise spécialisée
                      en matière de technologies de l'information pour aider les
                      entreprises à résoudre des problèmes techniques et à
                      améliorer leur efficacité opérationnelle.
                    </p>
                  </div>
                  <div className="tags">
                    <a href="#">Strategie</a>
                    <a href="#">Consultation</a>
                    <a href="#">Management</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div
                className="service-box mb-4 wow fadeInUp"
                data-wow-delay="0.2s"
              >
                <h5>
                  <a href="page-services-5.html"> Cloud/Devops </a>
                  <span className=" bg-[#1900CC] p-3 text-white  text-2xl rounded-full">
                    02
                  </span>
                </h5>
                <div className="icon">
                  {/* <img src="assets/img/icons/serv_icons/2.png" alt="" /> */}
                  <img src="assets/img/icons/serv_icons/3.png" alt="" />
                </div>
                <div className="info">
                  <div className="text">
                    <p>
                      Cloud : Accédez à la puissance et à la flexibilité du
                      cloud pour héberger, gérer et développer vos applications
                      en toute simplicité. DevOps : Améliorez l'efficacité de
                      votre cycle de développement en adoptant les principes
                      DevOps, combinant la collaboration entre les équipes de
                      développement et d'exploitation.
                    </p>
                  </div>
                  <div className="tags">
                    <a href="#">Management</a>
                    <a href="#">Sauvegarde</a>
                    <a href="#">Transfer</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div
                className="service-box mb-4 wow fadeInUp"
                data-wow-delay="0.4s"
              >
                <h5>
                  <a href="page-services-5.html"> Pilotage de projets </a>
                  <span className=" bg-[#1900CC] p-3 text-white  text-2xl rounded-full">
                    03
                  </span>
                </h5>
                <div className="icon">
                  <img src="assets/img/icons/serv_icons/4.png" alt="" />
                </div>
                <div className="info">
                  <div className="text">
                    <p>
                      Gérez vos projets avec succès en utilisant des
                      méthodologies de pilotage rigoureuses, des outils de
                      gestion de projet avancés et une communication
                      transparente pour atteindre vos objectifs
                    </p>
                  </div>
                  <div className="tags">
                    <a href="#">Ecommerce</a>
                    <a href="#">Landing Page</a>
                    <a href="#">CMS</a>
                    <a href="#">Plugin</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div
                className="service-box mb-4 mb-md-0 wow fadeInUp"
                data-wow-delay="0"
              >
                <h5>
                  <a href="page-services-5.html"> Cybersécurité </a>
                  <span className=" bg-[#1900CC] p-3 text-white  text-2xl rounded-full">
                    04
                  </span>
                </h5>
                <div className="icon">
                  <img src="assets/img/icons/serv_icons/2.png" alt="" />
                </div>
                <div className="info">
                  <div className="text">
                    <p>
                      Protégez vos données sensibles et votre infrastructure
                      informatique contre les menaces en constante évolution
                      grâce à des mesures de cybersécurité avancées, telles que
                      le chiffrement, les pare-feu, les solutions de détection
                      des intrusions et la sensibilisation à la sécurité.
                    </p>
                  </div>
                  <div className="tags">
                    <a href="#">Site web</a>
                    <a href="#">Application mobile</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div
                className="service-box mb-4 mb-md-0 wow fadeInUp"
                data-wow-delay="0.2s"
              >
                <h5>
                  <a href="page-services-5.html"> BI/ Big data </a>
                  <span className=" bg-[#1900CC] p-3 text-white  text-2xl rounded-full">
                    05
                  </span>
                </h5>
                <div className="icon">
                  <img src="assets/img/icons/serv_icons/5.png" alt="" />
                </div>
                <div className="info">
                  <div className="text">
                    <p>
                      Utilisez les technologies avancées de Business
                      Intelligence et du Big Data pour transformer vos données
                      en informations exploitables. Analysez et visualisez vos
                      vastes volumes de données afin de prendre des décisions
                      éclairées et stratégiques pour votre entreprise.
                    </p>
                  </div>
                  <div className="tags">
                    <a href="#">Cloud Stockage</a>
                    <a href="#">Hebergement & VPS</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="service-box wow fadeInUp" data-wow-delay="0.4s">
                <h5>
                  <a href="page-services-5.html"> Infra/Gitops </a>
                  <span className=" bg-[#1900CC] p-3 text-white  text-2xl rounded-full">
                    06
                  </span>
                </h5>
                <div className="icon">
                  <img src="assets/img/icons/serv_icons/6.png" alt="" />
                </div>
                <div className="info">
                  <div className="text">
                    <p>
                      Simplifiez la gestion de votre infrastructure en adoptant
                      l'approche GitOps. Utilisez Git comme source de vérité
                      pour votre configuration d'infrastructure, permettant
                      ainsi une gestion efficace, reproductible et traçable.
                      Automatisez les déploiements, les mises à jour et la
                      surveillance.
                    </p>
                  </div>
                  <div className="tags">
                    <a href="#">Unity 2D/3D</a>
                    <a href="#">VR/AR</a>
                    <a href="#">iOS & Android</a>
                    <a href="#">HTC Vive</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <img
        src="assets/img/services/ser_shap_l.png"
        alt=""
        className="ser_shap_l"
      />
      <img
        src="assets/img/services/ser_shap_r.png"
        alt=""
        className="ser_shap_r"
      />
    </section>
  );
};

export default NosServices;
