import React from "react";
import Slider from "../LogoSlider/Slider";

const IlNousFontConfiance = () => {
  return (
    <section class="clients style-5">
      <div class="container">
        <div class="section-head mb-70 style-6 text-center">
          <h2 class="mb-20">
            Ils nous font
            <span>
              <small> Confiance </small>{" "}
            </span>
          </h2>
          <p class="color-666 ">
            Plus de 5 000 entreprises et partenaires font confiance et
            choisissent ADVSKILL
          </p>
        </div>
        <Slider />
      </div>
    </section>
  );
};

export default IlNousFontConfiance;
