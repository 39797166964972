import React from "react";

const Mention = () => {
  return (
    <div>
      <div id="preloader"> </div>

      <div className="section-head text-center mb-60 style-5">
        <h2 className="mb-20">
          MENTIONS<span> LÉGALES </span>{" "}
        </h2>
        <div className="flex items-center justify-center">
          <div className="grid grid-cols-1 gap-6 sm:grid-cols- max-w-sm p-6 rounded-lg ">
            <p className="text-2xl text-center text-gray-900 p-2">
              Editeur du site : AdvSkill Siège social : 14 Rue d’Orléans 92200
              Neuilly Seine - France RCS: Nanterre B 799 919 493 SIRET:
              79991949300046 TVA intracommunautaire : FR25799919493 Adresse
              électronique : contact@advskill.com Téléphone : 08 92 97 63 66
              Directeur de la publication : NIAMBRUI Edith Hébergeur du site :
              ADVSKILL
            </p>
            <h1 className="text-center font-bold text-2xl">
              Propriété intellectuelle
            </h1>
            <p className="text-2xl text-center text-gray-900 p-2">
              Le site AdvSkill et l'ensemble de son contenu (textes, images,
              vidéos, etc.) sont protégés par le droit de la propriété
              intellectuelle. Toute reproduction, représentation, modification,
              diffusion ou exploitation totale ou partielle du contenu, sans
              l'autorisation expresse de AdvSkill, est strictement interdite et
              pourrait constituer une violation des lois applicables en matière
              de propriété intellectuelle.
            </p>
            <h1 className="text-center font-bold text-2xl">
              Collecte de données personnelles
            </h1>
            <p className="text-2xl text-center text-gray-900 p-2">
              AdvSkill collecte des données personnelles dans le respect des
              dispositions légales en vigueur, notamment du règlement général
              sur la protection des données (RGPD). Pour en savoir plus sur la
              collecte et le traitement de vos données personnelles, veuillez
              consulter notre politique de confidentialité.
            </p>
            <h1 className="text-center font-bold text-2xl">Cookies</h1>
            <p className="text-2xl text-center text-gray-900 p-2">
              Le site AdvSkill utilise des cookies. Les cookies sont de petits
              fichiers texte qui sont stockés sur votre ordinateur ou votre
              appareil lorsque vous visitez le site. Ils sont utilisés pour
              améliorer votre expérience de navigation et fournir des
              informations anonymes sur la manière dont vous utilisez le site.
              Vous pouvez configurer votre navigateur pour refuser
              l'installation des cookies, mais cela pourrait limiter certaines
              fonctionnalités du site.
            </p>
            <h1 className="text-center font-bold text-2xl">Responsabilité</h1>
            <p className="text-2xl text-center text-gray-900 p-2">
              AdvSkill ne saurait être tenu responsable des dommages directs ou
              indirects résultant de l'utilisation du site ou de l'impossibilité
              d'y accéder, ainsi que des éventuelles erreurs ou omissions dans
              le contenu du site. AdvSkill se réserve également le droit de
              modifier ou de supprimer tout contenu du site sans préavis.
            </p>
            <h1 className="text-center font-bold text-2xl">
              Juridiction compétente
            </h1>
            <p className="text-2xl text-center text-gray-900 p-2">
              Tout litige en relation avec l'utilisation du site AdvSkill est
              soumis au droit français. Les tribunaux français sont seuls
              compétents pour connaître de ces litiges.
              <br />
              <a
                href="https://www.linkedin.com/company/advancetechnology/"
                class="font-blod text-blue-600 dark:text-blue-500 hover:underline">
                Vistez notre page LinkedIn
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mention;
