import React from 'react'

const ContactForm = () => {
  return (
    <section className="contact section-padding pt-0 style-6">
            <div className="container">
                <div className="content">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <form action="contact.php" className="form" method="post">
                                <p className="text-center text-danger fs-12px mb-30">Les champs obligatoires *</p>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="form-group mb-20">  
                                            <input type="text" name="name" className="form-control" placeholder="Nom"/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group mb-20">
                                            <input type="text" name="email" className="form-control" placeholder=" Addresse e-mail *"/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group mb-20">
                                            <input type="text" name="phone" className="form-control" placeholder="Numéro de téléphone (optionnel)"/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group mb-20">
                                            <input type="text" name="website" className="form-control" placeholder="Site internet (optionnel)"/>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="form-group mb-20">
                                            <select name="option" className="form-select">
                                                <option value="how can we help" selected>Comment pouvons nous vous aider?</option>
                                                <option value="option 1">option 1</option>
                                                <option value="option 2">option 2</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="form-group">
                                            <textarea rows="10" className="form-control" placeholder=""></textarea>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 text-center">
                                        <div className="form-check d-inline-flex mt-30 mb-30">
                                            <input className="form-check-input me-2 mt-0" type="checkbox" value="" id="flexCheckDefault"/>
                                            <label className="form-check-label small" for="flexCheckDefault">
                                                J'accepte tous les  <a href="#" className="text-decoration-underline">Terms & Conditons</a>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 text-center">
                                        <input type="submit" value="Envoyer votre demande" className="btn rounded-pill bg-blue4 fw-bold text-white text-light fs-12px"/>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <img src="assets/img/icons/contact_a.png" alt="" className="contact_a"/>
                    <img src="assets/img/icons/contact_message.png" alt="" className="contact_message"/>
                </div>
            </div>
        </section>
  )
}

export default ContactForm