import React, { useState, useRef, useEffect } from "react";
import logo from "./../../assets/logo.svg";
import { NavLink } from "react-router-dom";
import {
  BsFillTelephoneFill,
  BsInstagram,
  BsFacebook,
  BsLinkedin,
} from "react-icons/bs";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [open, setOpen] = useState(false);

  const dropdownRef = useRef(null);
  let hoverTimeout;

  const handleMouseOver = () => {
    clearTimeout(hoverTimeout);
    setOpen(true);
  };

  const handleMouseLeave = () => {
    hoverTimeout = setTimeout(() => {
      setOpen(false);
    }, 300); // Adjust the delay (in milliseconds) as needed
  };

  useEffect(() => {
    return () => {
      clearTimeout(hoverTimeout);
    };
  }, []);

  return (
    <header className="header">
      <nav className="navbar navbar-expand-lg navbar-light style-1 md:flex ">
        <div className="container space-x-5">
          <NavLink to="/" className="navbar-brand" data-scroll-nav="0">
            <img className="w-15 md:w-30 lg:w-40" src={logo} alt="Logo" />
          </NavLink>
          {/* hamburger button starts here*/}

          {/* Hover menu setting  start here*/}
          <div className="block lg:hidden text-white bg-[#1900CC] rounded-full">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="flex items-center px-3 py-2 rounded text-black-500 hover:text-black-400">
              <svg
                className={`fill-current h-6 w-6 ${
                  isOpen ? "hidden" : "block"
                }`}
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
              </svg>
              <svg
                className={`fill-current h-6 w-6 ${
                  isOpen ? "block" : "hidden"
                }`}
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z" />
              </svg>
            </button>
          </div>
          <div
            className={`w-full block flex-grow lg:flex lg:items-center lg:w-auto ${
              isOpen ? "block" : "hidden"
            }`}>
            {/* hamburger button ends here*/}

            <ul className="navbar-nav place-content-between place-items-start m-auto mt-3 pt-2 pe-80 lg:flex-grow ">
              <li className="text-base font-semibold">
                <NavLink
                  className="  block mt-2 lg:inline-block lg:mt-0 mr-6 "
                  to="/">
                  Accueil
                </NavLink>
              </li>
              {/* Hover menu  starts here*/}
              <li
                // onMouseLeave={() => setOpen(false)}
                onMouseOver={handleMouseOver}
                onMouseLeave={handleMouseLeave}
                className=" relative text-base font-semibold dropdown  block mt-2 lg:inline-block lg:mt-0 mr-4 ">
                <NavLink
                  onMouseOver={() => setOpen(true)}
                  transitionDuration={3000}
                  className="flex items-center">
                  Nous connaitre
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-4 h-5">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                    />
                  </svg>
                </NavLink>
                <ul
                  className={`dropdown-menu navbarDropdown1 absolute  w-4 py-2 mt-2 rounded-lg shadow-xl ${
                    open ? "block" : "hidden"
                  }`}>
                  {/* Hover menu  ends here*/}
                  <li>
                    <NavLink className="dropdown-item" to="/qui-sommes-nous">
                      Qui sommes-nous ?
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="dropdown-item" to="nos-valeurs">
                      Nos Valeurs
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="dropdown-item" to="/notre-expertise">
                      Notre Expertise
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="dropdown-item" to="/mentions-legales">
                      Mentions légales
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li className="text-base font-semibold  block mt-2 lg:inline-block lg:mt-0 mr-4">
                <NavLink className="flex items-center" to="/recrutement">
                  Recrutement
                </NavLink>
              </li>
              {/* <li className="text-base font-semibold">
              <NavLink className="" to="/blog">Actualités</NavLink>
            </li> */}
            </ul>
            <div className="nav-side mr-4 mt-3 pl-2">
              <div className="hotline pe-4">
                <div className="icon me-3 ml-5">
                  <BsFillTelephoneFill />
                </div>
                <div className="text-black">
                  <h6 className="mt-2 font-normal-200 text-sm pr-2">
                    Appelez-nous au:
                  </h6>
                  <h6 className="text-[#1900CC] font-bold">
                    +33 1 76 43 34 21
                  </h6>
                </div>
              </div>
              {/* social media icons */}
              <div className="hotline">
                <div className="block mt-2 lg:inline-block lg:mt-0 mr-2 ">
                  <small className="text-black mt-2 font-bold pr-3">
                    <h6 className="pb-2 ml-5">Nous suivre sur:</h6>
                    <div className="icon me-3 ml-5">
                      <BsLinkedin />
                    </div>
                    <div className="icon me-3">
                      <BsFacebook />
                    </div>
                    <div className="icon me-3">
                      <BsInstagram />
                    </div>
                  </small>
                </div>
              </div>
              <div className="qoute-nav ps-4">
                <NavLink
                  className="btn sm-butn butn-gard rounded-full border-0 text-white block mt-2 lg:inline-block lg:mt-0 mr-4]"
                  to="/contacts">
                  <span>Contactez-nous</span>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
