import React from "react";

const NousChoisir = () => {
  return (
    <section
      className="choose-us section-padding pt-0 style-1"
      data-scroll-index="3"
    >
      <div className="container mt-5">
        <div className="row justify-content-end">
          <div className="col-lg-5">
            <div className="info">
              <div className="section-head mb-60  text-3xl">
                <h6 className="color-main text-uppercase font-bold fadeInUp">
                  Pourquoi nous choisir ?
                </h6>
                <h2 className="wow fadeInUp ">
                  Accélerez votre business
                  <span className="fw-normal">
                    avec les nouvelles technologies
                  </span>
                </h2>
              </div>
              <div className="text">
                <p>
                  Nos équipes sont composées uniquement d'ingénieurs de très
                  haut niveau, vous permettant ainsi de traiter les
                  problématiques mêmes les plus complexes, avec les technologies
                  de pointe, et dans le respect des standards les plus élevés.
                </p>
              </div>
              <ul>
                <li className="wow fadeInUp">
                  <span className="icon">
                    <i className="bi bi-check2"></i>
                  </span>
                  <h6>
                    Des consultants hautement qualifiés et expérimentés dans
                    tous les domaines de l'IT.
                  </h6>
                </li>
                <li className="wow fadeInUp">
                  <span className="icon">
                    <i className="bi bi-check2"></i>
                  </span>
                  <h6>
                    Une très grande réactivité à vous trouver la ressource
                    spécifique à votre besoin.
                  </h6>
                </li>
                <li className="wow fadeInUp">
                  <span className="icon">
                    <i className="bi bi-check2"></i>
                  </span>
                  <h6>Une grande mobilité de nos consultants.</h6>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="hidden md:block">
        <img
          src="assets/img/choose_us/choose_lines.svg"
          alt=""
          className="choose-us-img"
        />
        <img
          src="assets/img/choose_us/choose_brands.png"
          alt=""
          className="choose-us-brands"
        />
        <img
          src="assets/img/choose_us/choose_bubbles.png"
          alt=""
          className="choose-us-bubbles"
        />
      </div>
    </section>
  );
};

export default NousChoisir;
