import React from "react";

const FormulaireProfile = () => {
  return (
    <div>
      <section className="career-form section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="form-numbers">
                <h2> Nous recherchons des personnes Talentueuses</h2>
                <div className="career-numbers mt-50"></div>
              </div>
            </div>
            <div className="col-lg-6">
              <form action="" className="form">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group mb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Nom complet"
                      />
                      <span className="icon">
                        {" "}
                        <i className="fas fa-user"></i>{" "}
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group mb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Adresse e-mail"
                      />
                      <span className="icon">
                        {" "}
                        <i className="fas fa-envelope"></i>{" "}
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group mb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Numéro de téléphone"
                      />
                      <span className="icon">
                        {" "}
                        <i className="fas fa-phone"></i>{" "}
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group mb-4 upload-card">
                      <div className="form-control">
                        <span id="upload_text">
                          {" "}
                          <i className="fas fa-cloud"></i> Uploader votre CV
                        </span>
                        <input type="file" className="upload_input" />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group mb-4">
                      <textarea
                        rows="7"
                        className="form-control"
                        placeholder="Lettre de motivation"
                      ></textarea>
                      <span className="icon">
                        {" "}
                        <i className="fas fa-pen"></i>{" "}
                      </span>
                    </div>
                  </div>
                </div>
                <button className="btn bg-white sm-butn mt-4 rounded-3">
                  <span className="text-dark">
                    {" "}
                    Postuler!{" "}
                    <i className="fal fa-long-arrow-right ms-2 color-blue5"></i>{" "}
                  </span>
                </button>
              </form>
            </div>
          </div>
        </div>
        <img src="assets/img/careers/map.png" alt="" className="map_img" />
      </section>
    </div>
  );
};

export default FormulaireProfile;
