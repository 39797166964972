import React from "react";

const CequiNousDistingue = () => {
  return (
    <section class="choose-us section-padding style-6">
      <div class="container">
        <div class="row justify-content-between">
          <div class="col-lg-6">
            <div class="img">
              <img
                className="w-15 md:w-35 lg:w-58"
                src="assets/img/choose_us/3d_vector1.svg"
                alt=""
              />
            </div>
          </div>
          <div class="col-lg-5">
            <div class="info">
              <div class="section-head mb-20 style-6">
                <h2>
                  {" "}
                  Ce qui nous
                  <span>
                    {" "}
                    <small> distingue </small>{" "}
                  </span>
                </h2>
              </div>

              <p class="mb-3 text-gray-700 dark:text-gray-500">
                ADVSKILL - Connectez-vous à l'Innovation, la Diversité et
                l'Engagement. Chez ADVSKILL, nous sommes une société de services
                informatiques dédiée à l'innovation, à la diversité et à
                l'engagement.
              </p>
              <div class="grid grid-cols-1 gap-6 sm:grid-cols-2">
                <p class="mb-3 text-gray-700 dark:text-gray-500">
                  Nous comprenons que dans un monde numérique en constante
                  évolution, il est essentiel d'exploiter le pouvoir de la
                  technologie pour réussir sur le marché concurrentiel
                  d'aujourd'hui. L'innovation est le moteur qui nous pousse à
                  repousser les limites de la technologie.
                </p>
                <p class="mb-3 text-gray-700 dark:text-gray-500">
                  Nous investissons dans la recherche et le développement pour
                  rester à l'avant-garde des dernières avancées technologiques.
                  Notre équipe d'experts passionnés et talentueux est
                  constamment à la recherche de nouvelles idées et de nouvelles
                  solutions pour vous aider
                </p>
              </div>
              <p class="mb-3 text-gray-700 dark:text-gray-500">
                à atteindre vos objectifs commerciaux. Que ce soit dans le
                développement de logiciels, l'intelligence artificielle, le
                cloud computing ou l'Internet des objets, nous vous offrons des
                solutions innovantes qui amélioreront votre efficacité, votre
                agilité et votre compétitivité sur le marché.
              </p>

              {/* <div class="text mb-50">
                <p>
                  Nous sommes passionnés par notre travail. Nos concepteurs
                  gardent une longueur d'avance pour proposer des sites web
                  attrayants et conviviaux qui permettront à votre entreprise de
                  se démarquer.
                </p>
              </div>
              <ul>
                <li class="d-flex mb-40">
                  <small class="icon-60 bg-gray rounded-circle color-blue4 d-inline-flex align-items-center justify-content-center me-4 flex-shrink-0">
                    <img src="assets/img/icons/ch4.png" alt="" />
                  </small>
                  <div class="inf">
                    <h5>Prix abordable</h5>
                    <p class="fs-12px color-666 mt-2">
                      L'immersion dans les nanotechnologies et le haut niveau
                      d'information permettront d'éviter que l'on se concentre
                      uniquement sur les questions de santé et de sécurité. de
                      se concentrer uniquement sur
                    </p>
                  </div>
                </li>
                <li class="d-flex mb-40">
                  <small class="icon-60 bg-gray rounded-circle color-blue4 d-inline-flex align-items-center justify-content-center me-4 flex-shrink-0">
                    <img src="assets/img/icons/ch5.png" alt="" />
                  </small>
                  <div class="inf">
                    <h5>Conseil d'experts de haut niveau</h5>
                    <p class="fs-12px color-666 mt-2">
                      Nos experts de haut niveau, avec de nombreuses années
                      d'expérience, vous donneront les meilleures solutions pour
                      votre entreprise. solutions pour votre entreprise
                    </p>
                  </div>
                </li>
                <li class="d-flex">
                  <small class="icon-60 bg-gray rounded-circle color-blue4 d-inline-flex align-items-center justify-content-center me-4 flex-shrink-0">
                    <img src="assets/img/icons/ch6.png" alt="" />
                  </small>
                  <div class="inf">
                    <h5>Support dédié 24/7</h5>
                    <p class="fs-12px color-666 mt-2">
                      L'assistance à la clientèle est toujours notre priorité
                      numéro un.
                    </p>
                  </div>
                </li>
              </ul> */}
            </div>
          </div>
        </div>
        <p class="mt-3 mb-3 text-gray-700 dark:text-gray-500 text-center">
          La diversité est notre force. Nous croyons fermement que la diversité
          des perspectives et des talents est essentielle pour stimuler
          l'innovation <br />
          et la créativité. Nous nous engageons à favoriser un environnement
          inclusif <br />
          où chacun peut apporter sa contribution unique.
        </p>
        <div class="grid grid-cols-1 gap-6 sm:grid-cols-3">
          <p class="mb-3 text-gray-700 dark:text-gray-500">
            Notre équipe est composée d'experts issus de différents horizons, de
            cultures diverses et de compétences variées. Cette diversité nous
            permet d'aborder les problèmes sous différents angles et de fournir
            des solutions plus complètes et adaptées à vos besoins spécifiques.
            Nous sommes là pour vous accompagner à chaque étape du processus,
            depuis la conception et le développement jusqu'à
          </p>
          <p class="mb-3 text-gray-700 dark:text-gray-500">
            L'engagement est au cœur de tout ce que nous faisons. Nous sommes
            déterminés à être votre partenaire de confiance, qui comprend vos
            défis et s'engage à vous fournir des solutions fiables et durables.
            Notre équipe d'experts se consacre à votre succès et travaille en
            étroite collaboration avec vous pour garantir que nos services
            répondent à vos attentes les plus élevées. la mise en œuvre et le
            support technique
          </p>
          <p class="mb-3 text-gray-700 dark:text-gray-500">
            En choisissant ADVSKILL, vous bénéficierez d'une expertise inégalée,
            d'une culture d'innovation et d'un engagement envers l'excellence.
            Nous sommes fiers de notre capacité à créer des solutions
            technologiques de qualité supérieure qui répondent aux défis les
            plus complexes de nos clients. Avec ADVSKILL, vous pouvez vous
            connecter à l'innovation, la diversité et l'engagement.
          </p>
        </div>
        <p class="mb-3 text-gray-700 dark:text-gray-500 text-center ">
          Contactez-nous dès aujourd'hui pour découvrir comment notre approche
          unique peut transformer votre entreprise et vous aider <br /> à
          atteindre de nouveaux sommets technologiques. ADVSKILL -
          Connectez-vous à l'innovation, la diversité et l'engagement.
        </p>
      </div>
      <img
        src="assets/img/about/about_s6_bubbles.png"
        alt=""
        class="bubbles rotate-center"
      />
    </section>
  );
};

export default CequiNousDistingue;
