import Layout from "./components/Layouts/Layout";
import Navbar from "./components/Navbar/Navbar";
import WhatsappButton from "./components/whatsappButton/WhatsappButton";
import "./index.css";

function App() {
  return (
    <div>
      <Navbar />
      <Layout />
      <WhatsappButton />
    </div>
  );
}

export default App;
