import React from "react";

const NosValeurs = () => {
  return (
    <section class="about style-10 section-padding">
      <div id="preloader"> </div>
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6">
            <div class="imgs">
              <div class="row gx-3">
                <div class="col-lg-6 pt-60">
                  <div class="img img-cover">
                    <img src="assets/img/about/about_10_1.jpg" alt="" />
                  </div>
                  <div class="img img-cover mt-3">
                    <img src="assets/img/about/about2_2.jpg" alt="" />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="img img-cover">
                    <img src="assets/img/about/about_10_2.jpg" alt="" />
                  </div>
                  <div class="img img-cover mt-3">
                    <img src="assets/img/about/about_10_3.jpg" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="info px-lg-5">
              <div class="section-head style-3 mb-40">
                <h2 class="mb-20">
                  {" "}
                  Le triptyque IDE : Innovation Diversité Engagement{" "}
                  <span> </span>{" "}
                </h2>
              </div>
              <ul class="nav nav-pills" id="pills-tab" role="tablist">
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link active"
                    id="abt1-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#abt1"
                    type="button"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true"
                  >
                    INNOVATION
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link"
                    id="abt2-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#abt2"
                    type="button"
                    role="tab"
                    aria-controls="pills-profile"
                    aria-selected="false"
                  >
                    DIVERSITE
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link"
                    id="abt3-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#abt3"
                    type="button"
                    role="tab"
                    aria-controls="pills-profile"
                    aria-selected="false"
                  >
                    ENGAGEMENT
                  </button>
                </li>
              </ul>
              <div class="tab-content" id="pills-tabContent">
                <div
                  class="tab-pane fade show active"
                  id="abt1"
                  role="tabpanel"
                >
                  <p class="text">
                    {" "}
                    Découvrez une entreprise qui embrasse la valeur de
                    l'innovation et qui vous invite à repousser les limites de
                    la créativité et de la transformation.{" "}
                  </p>
                  <p class="text">
                    Chez ADVSKILL, l'innovation est notre moteur, notre passion
                    et notre engagement envers vous.
                  </p>
                  <p class="text">
                    Nous croyons fermement que l'innovation est la clé pour se
                    démarquer dans un monde en constante évolution. C'est
                    pourquoi nous investissons dans la recherche, le
                    développement et la collaboration pour vous offrir des
                    produits/services qui répondent à vos besoins actuels et
                    anticipent vos besoins futurs.
                  </p>
                  <p class="text">
                    Notre culture de l'innovation se nourrit de la curiosité et
                    du désir de remettre en question le statu quo. Nous ne nous
                    contentons pas de suivre les tendances, nous les créons.
                    Notre équipe talentueuse est constamment à la recherche de
                    nouvelles idées, de nouvelles technologies et de nouvelles
                    approches pour vous offrir des solutions uniques et
                    disruptives.
                  </p>
                  <p class="text"></p>
                </div>
                <div class="tab-pane fade" id="abt2" role="tabpanel">
                  <p class="text">
                    Découvrez une entreprise qui valorise et célèbre la
                    diversité. Chez ADVSKILL, nous croyons fermement que la
                    diversité est une richesse et une force qui stimule
                    l'innovation, favorise la créativité et renforce notre tissu
                    social.
                  </p>
                  <p class="text">
                    Nous reconnaissons que chaque individu est unique, avec ses
                    expériences, ses compétences et ses perspectives uniques.
                    C'est pourquoi nous nous engageons à créer un environnement
                    inclusif où chacun se sent valorisé, respecté et écouté.
                  </p>
                  <p class="text">
                    Nous embrassons la diversité culturelle, ethnique,
                    religieuse, d'âge et de capacité. Nous croyons que cette
                    diversité nous donne une vision plus large du monde, nous
                    permettant ainsi d'appréhender les défis de manière plus
                    complète et d'élaborer des solutions novatrices.
                  </p>
                  <p class="text">
                    Chez ADVSKILL, la diversité n'est pas seulement une notion
                    abstraite, mais une réalité tangible. Nous nous efforçons de
                    recruter et de développer une équipe diversifiée, en mettant
                    l'accent sur l'égalité des chances et en favorisant
                    l'inclusion à tous les niveaux de notre organisation.
                  </p>
                  <p class="text">
                    Nous nous engageons également à travailler avec des
                    partenaires et des fournisseurs qui partagent notre vision
                    de la diversité. En encourageant un réseau de collaboration
                    inclusif, nous créons des opportunités pour tous et
                    contribuons à un écosystème commercial plus équitable et
                    équilibré.
                  </p>
                  <p class="text"></p>
                  <p class="text"></p>
                </div>

                <div class="tab-pane fade" id="abt3" role="tabpanel">
                  <p class="text">
                    Êtes-vous prêt à vous engager pleinement pour atteindre vos
                    objectifs et donner vie à votre vision ?
                  </p>
                  <p class="text">
                    Chez ADVSKILL, nous croyons fermement aux valeurs
                    d'engagement et nous sommes là pour vous accompagner dans
                    cette aventure.
                  </p>
                  <p class="text">
                    Notre entreprise repose sur des principes solides qui
                    placent l'engagement au cœur de tout ce que nous faisons.
                    Nous croyons en la responsabilité, en la détermination et en
                    la persévérance, des valeurs qui vous aideront à surmonter
                    tous les obstacles sur votre chemin vers le succès.
                  </p>
                  <p class="text">
                    Nous comprenons que pour atteindre des résultats
                    exceptionnels, il est essentiel de rester fidèle à vos
                    convictions et de faire preuve d'intégrité. C'est pourquoi
                    nous nous engageons à agir de manière transparente et
                    éthique, en veillant à ce que nos actions reflètent nos
                    valeurs et renforcent la confiance que vous placez en nous.
                  </p>
                  <p class="text">
                    La passion est une force motrice qui anime nos équipes et
                    qui nous pousse à vous offrir des solutions innovantes et de
                    qualité. Nous sommes passionnés par ce que nous faisons et
                    nous sommes déterminés à apporter une valeur ajoutée à votre
                    vie et à votre entreprise.
                  </p>
                  <p class="text"></p>
                  <p class="text"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <img src="assets/img/about/about10_pattern.png" alt="" class="pattern" />
    </section>
  );
};

export default NosValeurs;
