import React from "react";

const QuiSommesNous = () => {
  return (
    <section class="about section-padding style-4 bg-1">
      <div class="content frs-content">
        <div class="container">
          <div class="row align-items-center justify-content-between">
            <div class="col-lg-6 ">
              <div class="img mb-30 mb-lg-0">
                <img src="assets/img/about/ipad.png" alt="" />
              </div>
            </div>
            <div class="col-lg-5 ">
              <div class="info">
                <div class="section-head mb-40 style-6">
                  <h2>
                    {" "}
                    Qui
                    <span>
                      <small>sommes nous ?</small>
                    </span>
                  </h2>
                </div>

                <p class="mb-3 text-gray-700 dark:text-gray-500">
                  ADVSKILL est une ESN française qui propose depuis 2014 son
                  expertise et ses conseils auprès d'un vaste panel
                  d'entreprises ( CAC40, ETI, PME et Startup ) dans les
                  nouvelles technologies ou des technologies plus anciennes.
                  L'équipe ADVSKILL est composée exclusivement d'Experts
                  techniques et managers confirmés.
                </p>
                <p class="mb-3 text-gray-700 dark:text-gray-500">
                  ADVSKILL accompagne sur la durée la transformation IT de
                  nombreuses entreprises en Europe. Nos dirigeants issus du
                  monde du conseil, ayant travaillé pour plusieurs grands
                  comptes du CAC40 ont mis en place des techniques de
                  recrutement très évoluées permettant de sourcer des experts
                  dans leur domaine. Ces derniers analysent vos besoins afin de
                  vous proposer les solutions les plus adaptées.
                </p>
              </div>
            </div>
          </div>
        </div>
        <img src="assets/img/about/about_s4_lines.png" alt="" class="lines" />
        <img src="assets/img/about/about_s4_bubble.png" alt="" class="bubble" />
      </div>
    </section>
  );
};

export default QuiSommesNous;
