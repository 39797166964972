import React from 'react'

const AllNews = () => {
  return (
    <div>
        <section className="all-news section-padding blog bg-transparent style-3">
            <div className="container">
                <div className="row gx-4 gx-lg-5">
                    <div className="col-lg-8">
                        <div className="card border-0 bg-transparent rounded-0 border-bottom brd-gray pb-30 mb-30">
                            <div className="row">
                                <div className="col-lg-5">
                                    <div className="img img-cover">
                                        <img src="assets/img/blog/6.png" className="radius-7" alt="..."/>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="card-body p-0">
                                        <small className="d-block date text">
                                            <a href="#" className="text-uppercase border-end brd-gray pe-3 me-3 color-blue4 fw-bold"> news </a>
                                            <i className="bi bi-clock me-1"></i>
                                            <a href="#" className="op-8">12 Days ago</a>
                                        </small>
                                        <a href="page-single-post-5.html" className="card-title mb-10">How To Become A Python Develop Expert</a>
                                        <p className="fs-13px color-666">If there’s one way that wireless technology has changed the way we work, it’s that will everyone [...]</p>
                                        <div className="auther-comments d-flex small align-items-center justify-content-between op-9">
                                            <div className="l_side d-flex align-items-center">
                                                <span
                                                    className="icon-10 rounded-circle d-inline-flex justify-content-center align-items-center text-uppercase bg-blue4 p-2 me-2 text-white">
                                                    a
                                                </span>
                                                <a href="#">
                                                    <small className="text-muted">By</small> Admin
                                                </a>
                                            </div>
                                            <div className="r-side mt-1">
                                                <i className="bi bi-chat-left-text me-1"></i>
                                                <a href="#">24</a>
                                                <i className="bi bi-eye ms-4 me-1"></i>
                                                <a href="#">774k</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card border-0 bg-transparent rounded-0 border-bottom brd-gray pb-30 mb-30">
                            <div className="row">
                                <div className="col-lg-5">
                                    <div className="img img-cover">
                                        <img src="assets/img/blog/4.jpeg" className="radius-7" alt="..."/>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="card-body p-0">
                                        <small className="d-block date text">
                                            <a href="#" className="text-uppercase border-end brd-gray pe-3 me-3 color-blue4 fw-bold">news</a>
                                            <i className="bi bi-clock me-1"></i>
                                            <a href="#" className="op-8">12 Days ago</a>
                                        </small>
                                        <a href="page-single-post-5.html" className="card-title mb-10">VR Game, Oppoturnity & Challenge</a>
                                        <p className="fs-13px color-666">If there’s one way that wireless technology has changed the way we work, it’s that will everyone [...]</p>
                                        <div className="auther-comments d-flex small align-items-center justify-content-between op-9">
                                            <div className="l_side d-flex align-items-center">
                                                <span
                                                    className="icon-10 rounded-circle d-inline-flex justify-content-center align-items-center text-uppercase bg-blue4 p-2 me-2 text-white">
                                                    a
                                                </span>
                                                <a href="#">
                                                    <small className="text-muted">By</small> Admin
                                                </a>
                                            </div>
                                            <div className="r-side mt-1">
                                                <i className="bi bi-chat-left-text me-1"></i>
                                                <a href="#">24</a>
                                                <i className="bi bi-eye ms-4 me-1"></i>
                                                <a href="#">774k</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card border-0 bg-transparent rounded-0 border-bottom brd-gray pb-30 mb-30">
                            <div className="row">
                                <div className="col-lg-5">
                                    <div className="img img-cover">
                                        <img src="assets/img/blog/10.png" className="radius-7" alt="..."/>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="card-body p-0">
                                        <small className="d-block date text">
                                            <a href="#" className="text-uppercase border-end brd-gray pe-3 me-3 color-blue4 fw-bold">tips
                                                & tricks</a>
                                            <i className="bi bi-clock me-1"></i>
                                            <a href="#" className="op-8">12 Days ago</a>
                                        </small>
                                        <a href="page-single-post-5.html" className="card-title mb-10">6 Tips To Help You Build Your Social Media Effeciency & Better</a>
                                        <p className="fs-13px color-666">If there’s one way that wireless technology has changed the way we work, it’s that will everyone [...]</p>
                                        <div className="auther-comments d-flex small align-items-center justify-content-between op-9">
                                            <div className="l_side d-flex align-items-center">
                                                <span
                                                    className="icon-10 rounded-circle d-inline-flex justify-content-center align-items-center text-uppercase bg-blue4 p-2 me-2 text-white">
                                                    a
                                                </span>
                                                <a href="#">
                                                    <small className="text-muted">By</small> Admin
                                                </a>
                                            </div>
                                            <div className="r-side mt-1">
                                                <i className="bi bi-chat-left-text me-1"></i>
                                                <a href="#">24</a>
                                                <i className="bi bi-eye ms-4 me-1"></i>
                                                <a href="#">774k</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card border-0 bg-transparent rounded-0 border-bottom brd-gray pb-30 mb-30">
                            <div className="row">
                                <div className="col-lg-5">
                                    <div className="img img-cover">
                                        <img src="assets/img/blog/11.png" className="radius-7" alt="..."/>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="card-body p-0">
                                        <small className="d-block date text">
                                            <a href="#" className="text-uppercase border-end brd-gray pe-3 me-3 color-blue4 fw-bold">tips
                                                & tricks</a>
                                            <i className="bi bi-clock me-1"></i>
                                            <a href="#" className="op-8">12 Days ago</a>
                                        </small>
                                        <a href="page-single-post-5.html" className="card-title mb-10">The New Trend Of Marketing With Tiktok, Should Or Not?</a>
                                        <p className="fs-13px color-666">If there’s one way that wireless technology has changed the way we work, it’s that will everyone [...]</p>
                                        <div className="auther-comments d-flex small align-items-center justify-content-between op-9">
                                            <div className="l_side d-flex align-items-center">
                                                <span
                                                    className="icon-10 rounded-circle d-inline-flex justify-content-center align-items-center text-uppercase bg-blue4 p-2 me-2 text-white">
                                                    a
                                                </span>
                                                <a href="#">
                                                    <small className="text-muted">By</small> Admin
                                                </a>
                                            </div>
                                            <div className="r-side mt-1">
                                                <i className="bi bi-chat-left-text me-1"></i>
                                                <a href="#">24</a>
                                                <i className="bi bi-eye ms-4 me-1"></i>
                                                <a href="#">774k</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card border-0 bg-transparent rounded-0 border-bottom brd-gray pb-30 mb-30">
                            <div className="row">
                                <div className="col-lg-5">
                                    <div className="img img-cover">
                                        <img src="assets/img/blog/9.png" className="radius-7" alt="..."/>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="card-body p-0">
                                        <small className="d-block date text">
                                            <a href="#" className="text-uppercase border-end brd-gray pe-3 me-3 color-blue4 fw-bold">tips
                                                & tricks</a>
                                            <i className="bi bi-clock me-1"></i>
                                            <a href="#" className="op-8">12 Days ago</a>
                                        </small>
                                        <a href="page-single-post-5.html" className="card-title mb-10">Workflow Strategy For E-Shop</a>
                                        <p className="fs-13px color-666">If there’s one way that wireless technology has changed the way we work, it’s that will everyone [...]</p>
                                        <div className="auther-comments d-flex small align-items-center justify-content-between op-9">
                                            <div className="l_side d-flex align-items-center">
                                                <span
                                                    className="icon-10 rounded-circle d-inline-flex justify-content-center align-items-center text-uppercase bg-blue4 p-2 me-2 text-white">
                                                    a
                                                </span>
                                                <a href="#">
                                                    <small className="text-muted">By</small> Admin
                                                </a>
                                            </div>
                                            <div className="r-side mt-1">
                                                <i className="bi bi-chat-left-text me-1"></i>
                                                <a href="#">24</a>
                                                <i className="bi bi-eye ms-4 me-1"></i>
                                                <a href="#">774k</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card border-0 bg-transparent rounded-0 border-bottom brd-gray pb-30 mb-30">
                            <div className="row">
                                <div className="col-lg-5">
                                    <div className="img img-cover">
                                        <img src="assets/img/blog/3.jpeg" className="radius-7" alt="..."/>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="card-body p-0">
                                        <small className="d-block date text">
                                            <a href="#" className="text-uppercase border-end brd-gray pe-3 me-3 color-blue4 fw-bold">tips
                                                & tricks</a>
                                            <i className="bi bi-clock me-1"></i>
                                            <a href="#" className="op-8">12 Days ago</a>
                                        </small>
                                        <a href="page-single-post-5.html" className="card-title mb-10">Wireframe For UI/UX</a>
                                        <p className="fs-13px color-666">If there’s one way that wireless technology has changed the way we work, it’s that will everyone [...]</p>
                                        <div className="auther-comments d-flex small align-items-center justify-content-between op-9">
                                            <div className="l_side d-flex align-items-center">
                                                <span
                                                    className="icon-10 rounded-circle d-inline-flex justify-content-center align-items-center text-uppercase bg-blue4 p-2 me-2 text-white">
                                                    a
                                                </span>
                                                <a href="#">
                                                    <small className="text-muted">By</small> Admin
                                                </a>
                                            </div>
                                            <div className="r-side mt-1">
                                                <i className="bi bi-chat-left-text me-1"></i>
                                                <a href="#">24</a>
                                                <i className="bi bi-eye ms-4 me-1"></i>
                                                <a href="#">774k</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card border-0 bg-transparent rounded-0 pb-30 mb-30 mb-lg-0 pb-lg-0">
                            <div className="row">
                                <div className="col-lg-5">
                                    <div className="img img-cover">
                                        <img src="assets/img/blog/12.png" className="radius-7" alt="..."/>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="card-body p-0">
                                        <small className="d-block date text">
                                            <a href="#" className="text-uppercase border-end brd-gray pe-3 me-3 color-blue4 fw-bold">tips
                                                & tricks</a>
                                            <i className="bi bi-clock me-1"></i>
                                            <a href="#" className="op-8">12 Days ago</a>
                                        </small>
                                        <a href="page-single-post-5.html" className="card-title mb-10">Freelancer Days 2022, What’s new?</a>
                                        <p className="fs-13px color-666">If there’s one way that wireless technology has changed the way we work, it’s that will everyone [...]</p>
                                        <div className="auther-comments d-flex small align-items-center justify-content-between op-9">
                                            <div className="l_side d-flex align-items-center">
                                                <span
                                                    className="icon-10 rounded-circle d-inline-flex justify-content-center align-items-center text-uppercase bg-blue4 p-2 me-2 text-white">
                                                    a
                                                </span>
                                                <a href="#">
                                                    <small className="text-muted">By</small> Admin
                                                </a>
                                            </div>
                                            <div className="r-side mt-1">
                                                <i className="bi bi-chat-left-text me-1"></i>
                                                <a href="#">24</a>
                                                <i className="bi bi-eye ms-4 me-1"></i>
                                                <a href="#">774k</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="pagination style-5 color-4 justify-content-center mt-60">
                            <a href="#" className="active">
                                <span>1</span>
                            </a>
                            <a href="#">
                                <span>2</span>
                            </a>
                            <a href="#">
                                <span>3</span>
                            </a>
                            <a href="#">
                                <span>4</span>
                            </a>
                            <a href="#">
                                <span>...</span>
                            </a>
                            <a href="#">
                                <span>20</span>
                            </a>
                            <a href="#">
                                <span className="text">next <i className="fas fa-chevron-right"></i> </span>
                            </a>
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="side-blog style-5 ps-lg-5 mt-5 mt-lg-0">

                            <form action="contact.php" className="search-form mb-50" method="post">
                                <h6 className="title mb-20 text-uppercase fw-normal">
                                    search
                                </h6>
                                <div className="form-group position-relative">
                                    <input type="text" className="form-control rounded-pill" placeholder="Type and hit enter"/>
                                    <button className="search-btn border-0 bg-transparent"> <i className="fas fa-search"></i> </button>
                                </div>
                            </form>

                            <div className="side-recent-post mb-50">
                                <h6 className="title mb-20 text-uppercase fw-normal">
                                    recent post
                                </h6>
                                <a href="page-single-post-5.html" className="post-card pb-3 mb-3 border-bottom brd-gray">
                                    <div className="img me-3">
                                        <img src="assets/img/blog/1.jpeg" alt=""/>
                                    </div>
                                    <div className="inf">
                                        <h6> Crypto Trend 2023 </h6>
                                        <p> If there’s one way that wireless technology has [...] </p>
                                    </div>
                                </a>
                                <a href="page-single-post-5.html" className="post-card pb-3 mb-3 border-bottom brd-gray">
                                    <div className="img me-3">
                                        <img src="assets/img/blog/2.jpeg" alt=""/>
                                    </div>
                                    <div className="inf">
                                        <h6> How To Become Web Developer </h6>
                                        <p> If there’s one way that wireless technology has [...] </p>
                                    </div>
                                </a>
                                <a href="page-single-post-5.html" className="post-card pb-3 mb-3 border-bottom brd-gray">
                                    <div className="img me-3">
                                        <img src="assets/img/blog/3.jpeg" alt=""/>
                                    </div>
                                    <div className="inf">
                                        <h6> Wireframe for UI/UX </h6>
                                        <p> If there’s one way that wireless technology has [...] </p>
                                    </div>
                                </a>
                                <a href="page-single-post-5.html" className="post-card">
                                    <div className="img me-3">
                                        <img src="assets/img/blog/7.png" alt=""/>
                                    </div>
                                    <div className="inf">
                                        <h6> AI With Fingerprint </h6>
                                        <p> If there’s one way that wireless technology has [...] </p>
                                    </div>
                                </a>
                            </div>

                            <div className="side-categories mb-50">
                                <h6 className="title mb-20 text-uppercase fw-normal">
                                    categories
                                </h6>
                                <a href="#" className="cat-item">
                                    <span> all </span>
                                    <span> 265 </span>
                                </a>
                                <a href="#" className="cat-item">
                                    <span> News </span>
                                    <span> 38 </span>
                                </a>
                                <a href="#" className="cat-item">
                                    <span> Technology </span>
                                    <span> 16 </span>
                                </a>
                                <a href="#" className="cat-item">
                                    <span> Tips & Tricks </span>
                                    <span> 85 </span>
                                </a>
                                <a href="#" className="cat-item">
                                    <span> Career </span>
                                    <span> 21 </span>
                                </a>
                                <a href="#" className="cat-item">
                                    <span> Community </span>
                                    <span> 874 </span>
                                </a>
                                <a href="#" className="cat-item">
                                    <span> Videos </span>
                                    <span> 54 </span>
                                </a>
                                <a href="#" className="cat-item border-0">
                                    <span> Others </span>
                                    <span> 85 </span>
                                </a>
                            </div>

                            <div className="side-newsletter mb-50">
                                <h6 className="title mb-10 text-uppercase fw-normal">
                                    newsletter
                                </h6>
                                <div className="text">
                                    Register now to get latest updates on promotions & coupons.
                                </div>
                                <form action="contact.php" className="form-subscribe" method="post">
                                    <div className="email-input d-flex align-items-center py-3 px-3 bg-white mt-3 radius-5">
                                        <span className="icon me-2 flex-shrink-0">
                                            <i className="far fa-envelope"></i>
                                        </span>
                                        <input type="text" placeholder="Email Address" className="border-0 bg-transparent fs-13px"/>
                                    </div>
                                    <button className="btn bg-blue4 sm-butn text-white hover-darkBlue w-100 mt-3 radius-5 py-3">
                                        <span>Subscribe</span>
                                    </button>
                                </form>
                            </div>

                            <div className="side-share mb-50">
                                <h6 className="title mb-20 text-uppercase fw-normal">
                                    social
                                </h6>
                                <a href="#" className="social-icon">
                                    <i className="fab fa-twitter"></i>
                                </a>
                                <a href="#" className="social-icon">
                                    <i className="fab fa-facebook-f"></i>
                                </a>
                                <a href="#" className="social-icon">
                                    <i className="fab fa-pinterest"></i>
                                </a>
                                <a href="#" className="social-icon">
                                    <i className="fab fa-goodreads-g"></i>
                                </a>
                                <a href="#" className="social-icon">
                                    <i className="fab fa-linkedin-in"></i>
                                </a>
                            </div>

                            <div className="side-insta mb-50">
                                <h6 className="title mb-20 text-uppercase fw-normal">
                                    our instagram
                                </h6>
                                <div className="d-flex justify-content-between flex-wrap">
                                    <a href="assets/img/blog/1.jpeg" className="insta-img img-cover" data-fancybox="gallery">
                                        <img src="assets/img/blog/1.jpeg" alt=""/>
                                        <i className="fab fa-instagram icon"></i>
                                    </a>
                                    <a href="assets/img/blog/10.png" className="insta-img img-cover" data-fancybox="gallery">
                                        <img src="assets/img/blog/10.png" alt=""/>
                                        <i className="fab fa-instagram icon"></i>
                                    </a>
                                    <a href="assets/img/blog/11.png" className="insta-img img-cover" data-fancybox="gallery">
                                        <img src="assets/img/blog/11.png" alt=""/>
                                        <i className="fab fa-instagram icon"></i>
                                    </a>
                                    <a href="assets/img/blog/12.png" className="insta-img img-cover" data-fancybox="gallery">
                                        <img src="assets/img/blog/12.png" alt=""/>
                                        <i className="fab fa-instagram icon"></i>
                                    </a>
                                    <a href="assets/img/blog/2.jpeg" className="insta-img img-cover" data-fancybox="gallery">
                                        <img src="assets/img/blog/2.jpeg" alt=""/>
                                        <i className="fab fa-instagram icon"></i>
                                    </a>
                                    <a href="assets/img/blog/3.jpeg" className="insta-img img-cover" data-fancybox="gallery">
                                        <img src="assets/img/blog/3.jpeg" alt=""/>
                                        <i className="fab fa-instagram icon"></i>
                                    </a>
                                </div>
                            </div>

                            <div className="side-tags">
                                <h6 className="title mb-20 text-uppercase fw-normal">
                                    popular tags
                                </h6>
                                <div className="content">
                                    <a href="#">WordPress</a>
                                    <a href="#">PHP</a>
                                    <a href="#">HTML/CSS</a>
                                    <a href="#">Figma</a>
                                    <a href="#">Technology</a>
                                    <a href="#">Marketing</a>
                                    <a href="#">Consultation</a>
                                    <a href="#">Seo</a>
                                    <a href="#">Envato</a>
                                    <a href="#">Psd</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}

export default AllNews