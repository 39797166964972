import React from 'react'

const ContactInfo = () => {
    return (
        <section className="community section-padding style-5">
            <div className="container">
                <div className="section-head text-center style-4 mb-40">
                    <small className="title_small">Contactez-nous</small>
                    <h2 className="mb-20">Prenez <span> contact  </span> </h2>
                    <p>Nous vous recontacterons après avoir reçu votre demande dans les 24 heures.</p>
                </div>
                <div className="content rounded-pill">
                    <div className="commun-card">
                        <div className="icon icon-45">
                            <img src="assets/img/icons/mail3d.png" alt="" />
                        </div>
                        <div className="inf">
                            <h5>contact@advskill.com </h5>
                        </div>
                    </div>
                    <div className="commun-card">
                        <div className="icon icon-45">
                            <img src="assets/img/icons/map3d.png" alt="" />
                        </div>
                        <div className="inf">
                            <h5>Neuily-sur-Seine, Île-de-France</h5>
                        </div>
                    </div>
                    <div className="commun-card">
                        <div className="icon icon-45">
                            <img src="assets/img/icons/msg3d.png" alt="" />
                        </div>
                        <div className="inf">
                            <h5>(+33) 1 76 43 34 21
                            </h5>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ContactInfo