import React from "react";
import whatsapp from "./../../assets/whatsapp.png";
import "./style.css";

const WhatsappButton = () => {
  return (
    <div>
      <a
        id="whatsapp-button"
        className="whatsapp-button animate-bounce"
        href="https://wa.link/pgq6oi">
        <img className="" src={whatsapp} width={70} alt="WhatsappLogo" />
      </a>

      <div id="whatsapp-popup" class="whatsapp-popup">
        <p>Discuter avec un agent?</p>
      </div>
    </div>
  );
};

export default WhatsappButton;
