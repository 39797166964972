import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import {
  Autoplay,
  Pagination,
  Navigation,
  EffectCoverflow,
} from "swiper/modules";
import logo from "../../components/LogoSlider/logo.json";

const breakpoints = {
  320: {
    slidesPerView: 2,
    spaceBetween: 10,
  },
  480: {
    slidesPerView: 3,
    spaceBetween: 20,
  },
  768: {
    slidesPerView: 4,
    spaceBetween: 30,
  },
  1024: {
    slidesPerView: 5,
    spaceBetween: 40,
  },
  1200: {
    slidesPerView: 5,
    spaceBetween: 50,
  },
};

const Slider = () => {
  return (
    <div className="h-[350px]">
      <Swiper
        breakpoints={breakpoints}
        loop={true}
        spaceBetween={0}
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={5}
        navigation={false}
        modules={[Autoplay, Pagination, Navigation, EffectCoverflow]}
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        pagination={true}
        autoplay={{
          delay: 1500,
          disableOnInteraction: false,
        }}
        className="h-[40%] sm:h-[50%]
        ">
        {logo.carousel.map((item) => (
          <SwiperSlide key={item.id}>
            <img src={item.image} alt={item.title} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Slider;
