import React from "react";
import NosServices from "../../components/NotreExpertise/NosServices";
import RegieClassique from "../../components/NotreExpertise/RegieClassique";

const NotreExpertises = () => {
  return (
    <div>
      <div id="preloader"> </div>
      <main className="services-page style-5">
        <NosServices />
        <RegieClassique />
      </main>
    </div>
  );
};

export default NotreExpertises;
