import React from 'react';

import Footer from "../Footer/Footer";
import Routes from "../../routes/Routers";
import Navbar from "../Navbar/Navbar";

const Layout = () => {
  return (
    <>
      <Routes />
      <Footer />
    </>
  );
};

export default Layout