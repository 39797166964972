import React from "react";

const ListProfile = () => {
  return (
    <section className="careers-positions pb-100">
      <div className="section-head text-center mb-60 style-5">
        <h2 className="mb-20">
          {" "}
          Nous recherchons des personnes <span> Talentueuses </span>{" "}
        </h2>
      </div>
      <div className="container">
        <div className="section-head text-center mb-60 style-5">
          <h2 className="mb-20">
            {" "}
            Missions<span> disponibles </span>{" "}
          </h2>
        </div>
        <div className="row">
          {/* MISSION1 */}
          <div className="col-lg-4">
            <a href="#" className="position-card mb-4">
              <h5> Développeur Full stack </h5>

              <ol className="space-y-4  list-decimal list-inside dark:text-gray-600">
                <ol>
                  <h2 className="text-base text-black font-semibold p-2">
                    Responsabilités principales
                  </h2>
                  <ul className="pl-5 mt-2 space-y-1 list-disc list-inside">
                    <li>
                      Participer à l'affinage du backlog, l'évaluation de la
                      complexité, la conception, l'écriture des US et des
                      spécifications.
                    </li>
                    <li>
                      Participer aux ateliers d'amélioration continue, à la
                      veille technologique, aux partages de compétences dans le
                      but d'améliorer l'expertise collective.
                    </li>
                    <li>
                      Coordonner les livrables et les différentes prestations
                      techniques.
                    </li>
                    <li>
                      Être sensible aux concepts : Data, sécurité, RGPD,
                      performance, tests unitaires et fonctionnels.
                    </li>
                    <li>
                      Assurer le maintien à jour de la documentation technique
                      (architecture, spécifications techniques).
                    </li>
                  </ul>
                </ol>

                <ol>
                  <h2 className="text-base text-black font-semibold p-2">
                    Expertises requises
                  </h2>
                  Pour la réalisation de la consultation, les compétences
                  suivantes sont requises :
                  <ul className="pl-5 mt-2 space-y-1 list-disc list-inside">
                    <li>
                      Excellentes références en framework ReactJS /Next Js/
                      Redux-Saga.
                    </li>
                    <li>
                      Très bon niveau en PHP (POO, design pattern). Is is a bad
                      idea too, keep things as flat as possible.
                    </li>
                    <li>
                      Très bonne connaissance du framework Symfony. Une
                      connaissance du framework API Platform est un plus.
                    </li>
                    <li>
                      Bonne connaissance des architectures web, notamment à fort
                      trafic (cache, CDN, webperf, REST, GraphQL, etc.).
                    </li>
                    <li>
                      Bonne connaissance des stratégies de tests,
                      d'industrialisation et d'intégration continue.
                      Connaissance des contextes agiles.
                    </li>
                    <li>
                      Références professionnelles en base de données
                      documentaire (MongoDB/Elastic Search).
                    </li>
                  </ul>
                </ol>
              </ol>

              <div className="time">
                <span className="me-4">
                  {" "}
                  <i className="fal fa-clock me-1 color-main"></i> Full-time{" "}
                </span>
                <span>
                  {" "}
                  <i className="fal fa-dollar-sign me-1 color-main p-4"></i> 50k
                  - $60k{" "}
                </span>
              </div>
              <span className="trend-mark">
                {" "}
                <i className="fas fa-bolt"></i>{" "}
              </span>
            </a>
          </div>

          {/* MISSION2 */}

          <div className="col-lg-4">
            <a href="#" className="position-card mb-4">
              <h5> Senior Designer UX / UI </h5>

              <ol class="space-y-4  list-decimal list-inside dark:text-gray-600">
                <ol>
                  <h2 className="text-base text-black font-semibold p-2">
                    Responsabilités principales
                  </h2>
                  Pour notre client, nous recherchons un Senior Designer UX
                  chargé de définir la stratégie et les processus UX pour un
                  domaine spécifique. Les principales missions du poste sont :
                  <ul className="pl-5 mt-2 space-y-1 list-disc list-inside">
                    <li>
                      Mener des recherches sur les utilisateurs et collaborer
                      avec les leaders du domaine commercial et numérique pour
                      définir la stratégie.
                    </li>
                    <li>
                      Être responsable de l'expérience utilisateur au sein du
                      domaine.
                    </li>
                    <li>
                      Gérer le processus de conception de la plateforme, de la
                      phase de cadrage à la livraison.
                    </li>
                    <li>
                      Être le propriétaire du bar UX (l'endroit où les équipes
                      se réunissent pour discuter de l'expérience utilisateur).
                    </li>
                    <li>
                      Animer des ateliers de conception pour favoriser la
                      co-création.
                    </li>
                    <li>Promouvoir le design system.</li>
                    <li>
                      Concevoir des interfaces en collaboration avec le lead U.I
                      designer.
                    </li>
                    <li>
                      Mesurer la satisfaction des utilisateurs, recueillir leurs
                      retours et analyser les données.
                    </li>
                    <li>
                      Planifier les tests utilisateurs et mesurer le score S.U.S
                      (Satisfaction User Scale).
                    </li>
                  </ul>
                </ol>

                <ol>
                  <h2 className="text-base text-black font-semibold p-2">
                    Expertises requises
                  </h2>
                  Pour la réalisation de la consultation, les compétences
                  suivantes sont requises :
                  <ul className="pl-5 mt-2 space-y-1 list-disc list-inside">
                    <li>
                      Pour ce poste, une expérience significative dans la
                      recherche d'utilisateurs, les ateliers de conception et
                      les tests utilisateurs est essentielle.
                    </li>
                    <li>
                      Vous devez également avoir une approche du design
                      thinking, une connaissance des lois de conception communes
                      et des directives mobiles first.
                    </li>
                    <li>
                      La conception et l'optimisation de formulaires, tant pour
                      le e-commerce que pour les produits B2E et B2B, font
                      également partie de vos expériences.
                    </li>
                    <li>
                      Vous devez être à l'aise dans la conception d'interfaces,
                      en créant des wireframes ou des mockups haute fidélité.
                    </li>
                    <li>
                      Une connaissance des méthodologies agiles, de l'UX
                      analytics et de l'outil Figma est également requise.
                    </li>
                  </ul>
                </ol>
              </ol>

              <div className="time">
                <span className="me-4">
                  {" "}
                  <i className="fal fa-clock me-1 color-main"></i> Full-time{" "}
                </span>
                <span>
                  {" "}
                  <i className="fal fa-dollar-sign me-1 color-main p-4"></i> 40k
                  - $50k{" "}
                </span>
              </div>
              <span className="trend-mark">
                {" "}
                <i className="fas fa-bolt"></i>{" "}
              </span>
            </a>
          </div>

          {/* MISSION3 */}

          <div className="col-lg-4">
            <a href="#" className="position-card mb-4">
              <h5> DevOps Expert </h5>

              <ol class="space-y-4  list-decimal list-inside dark:text-gray-600">
                <ol>
                  <h2 className="text-base text-black font-semibold p-2">
                    Responsabilités principales
                  </h2>
                  Pour l'un de nos clients, nous recherchons un DevOps Expert
                  ayant au moins 5 ans d'expérience. La prestation aura lieu à
                  Lille. Les responsabilités principales du poste sont les
                  suivantes :
                  <ul className="pl-5 mt-2 space-y-1 list-disc list-inside">
                    <li>
                      Travailler en collaboration avec l'équipe pour identifier
                      et développer des scripts afin d'automatiser les
                      traitements manuels.
                    </li>
                    <li>
                      Proposer, suivre et mettre en place des outils de qualité
                      de code.
                    </li>
                    <li>Mettre en place des tests unitaires.</li>
                    <li>
                      Mettre en place des solutions de monitoring telles que des
                      scripts, des e-mails, des rapports, etc.
                    </li>
                    <li>
                      Assister les équipes dans leurs réflexions sur les outils
                      visant à faciliter le quotidien.
                    </li>
                    <li>
                      Proposer des moyens d'automatiser partiellement le
                      processus de recette et les mettre en place.
                    </li>
                  </ul>
                </ol>

                <ol>
                  <h2 className="text-base text-black font-semibold p-2">
                    Expertises requises
                  </h2>
                  L'environnement technique pour ce poste de DevOps Expert
                  comprend les compétences suivantes :
                  <ul className="pl-5 mt-2 space-y-1 list-disc list-inside">
                    <li>Système d'exploitation : Linux Serveur web</li>
                    <li>
                      Nginx Outils de monitoring : Prometheus, ELK
                      (Elasticsearch, Logstash, Kibana), Grafana
                    </li>
                    <li>Outils d'intégration continue : Jenkins</li>
                    <li>Infrastructure as Code : Terraform, Ansible</li>
                    <li>Contrôle de version : Git</li>
                    <li>
                      Orchestration de conteneurs : Kubernetes (obligatoire)
                    </li>
                    <li>Conteneurisation : Docker</li>
                    <li>Plateforme de gestion de code : Gitlab</li>
                    <li>Cloud : AWS,Azure, GCP (Google Cloud Platform)</li>
                    <li>Outils de déploiement : GitHub Actions</li>
                  </ul>
                  Note : Ce poste de DevOps Expert requiert une expertise
                  technique dans les technologies et outils mentionnés
                  ci-dessus. Vous travaillerez en étroite collaboration avec
                  l'équipe et serez chargé de proposer, mettre en place et
                  suivre des solutions d'automatisation pour améliorer les
                  processus de développement et de déploiement. La maîtrise de
                  Kubernetes est obligatoire pour ce poste.
                </ol>
              </ol>

              <div className="time">
                <span className="me-4">
                  {" "}
                  <i className="fal fa-clock me-1 color-main"></i> Full-time
                </span>
                <span>
                  <i className="fal fa-dollar-sign me-1 color-main p-4"></i> 50k
                  - $60k
                </span>
              </div>
              <span className="trend-mark">
                <i className="fas fa-bolt"></i>
              </span>
            </a>
          </div>

          {/* MISSION4 */}

          <div className="col-lg-4">
            <a href="#" className="position-card mb-4">
              <h5> Développeur C#/.NET</h5>

              <ol class="space-y-4  list-decimal list-inside dark:text-gray-600">
                <ol>
                  <h2 className="text-base text-black font-semibold p-2">
                    Responsabilités principales
                  </h2>
                  Je suis à la recherche d'un Développeur .NET/C# pour l'un de
                  mes clients. Le développeur rejoindra une équipe de 6
                  personnes composée d'un intégrateur, d'un architecte logiciel,
                  d'un analyste et d'autres développeurs. Les développements
                  backend seront effectués en méthode Agile. Les principales
                  responsabilités du développeur seront les suivantes pour les
                  évolutions ou corrections :
                  <ul className="pl-5 mt-2 space-y-1 list-disc list-inside">
                    <li>
                      Développer et effectuer des tests unitaires pour les
                      fonctionnalités backend.
                    </li>
                    <li>
                      Documenter les modifications techniques apportées au code
                      de l'application.
                    </li>
                    <li>Mettre à jour les tickets sur Gitlab.</li>
                    <li>
                      Produire un compte-rendu hebdomadaire pour suivre
                      l'avancement des travaux confiés.
                    </li>
                  </ul>
                </ol>
                <ol>
                  <h2 className="text-base text-black font-semibold p-2">
                    Compétences requises
                  </h2>
                  Pour la réalisation de la consultation, les compétences
                  suivantes sont requises :
                  <ul className="pl-5 mt-2 space-y-1 list-disc list-inside">
                    <li>
                      Maîtrise du langage de programmation C# ou volonté de
                      monter en compétence sur C#.
                    </li>
                    <li>Expérience en développement backend .NET</li>
                    <li>
                      Capacité à travailler en équipe avec plusieurs
                      développeurs.
                    </li>
                    <li>
                      Connaissance du travail dans un environnement Agile SCRUM.
                    </li>
                  </ul>
                  <h2 className="text-base text-black font-semibold p-2">
                    Compétences techniques
                  </h2>
                  Les compétences techniques requises pour ce poste de
                  Développeur C#/.NET comprennent :
                </ol>
                <ul className="pl-5 mt-2 space-y-1 list-disc list-inside p-2">
                  <li>Maîtrise de .NET</li>
                  <li>Bonne connaissance de C#.</li>
                  <li>Connaissance de DevOps.</li>
                  <li>Utilisation de Git pour le contrôle de version.</li>
                </ul>
                Note : Ce poste de Développeur C#/.NET offre l'opportunité de
                rejoindre une équipe dynamique travaillant en méthode Agile
                SCRUM. La maîtrise de .NET et de C# est essentielle, mais si
                vous avez la volonté d'apprendre et de monter en compétence sur
                C#, vous serez également considéré. Le développement backend
                .NET est au cœur de cette mission, et une expérience de travail
                en équipe et de l'utilisation de Git est également requise.
              </ol>

              <div className="time">
                <span className="me-4">
                  {" "}
                  <i className="fal fa-clock me-1 color-main"></i> Full-time{" "}
                </span>
                <span>
                  {" "}
                  <i className="fal fa-dollar-sign me-1 color-main p-4"></i> 40k
                  - $50k{" "}
                </span>
              </div>
              <span className="trend-mark">
                {" "}
                <i className="fas fa-bolt"></i>{" "}
              </span>
            </a>
          </div>
          {/* MISSION5 */}

          <div className="col-lg-4">
            <a href="#" className="position-card mb-4">
              <h5> Architecte Cloud Azure/AWS</h5>

              <ol class="space-y-4  list-decimal list-inside dark:text-gray-600">
                <ol>
                  <h2 className="text-base text-black font-semibold p-2">
                    Responsabilités principales
                  </h2>
                  Pour l'un de nos clients basé en Île-de-France, nous
                  recherchons un expert en sécurité cloud. En raison de
                  l'orientation stratégique du groupe vers une augmentation du
                  chiffre d'affaires des logiciels, de la présence de clients de
                  grande envergure dans le portefeuille et de la stratégie SAAS
                  sur des clouds publics (AWS, Azure), il est crucial de
                  renforcer et structurer la politique de sécurité des offres
                  cloud. <br />
                  Les principales missions du poste sont les suivantes :
                  <ul class="pl-5 mt-2 space-y-1 list-disc list-inside">
                    <li>
                      Définir des politiques de sécurité des systèmes
                      d'information pour les offres Cloud/On-Premises, l'IT et
                      l'écosystème du client (sous-traitants, agents,
                      fournisseurs, etc.).
                    </li>
                    <li>
                      Réaliser des analyses de risques permettant de prioriser
                      les actions de suivi dans le domaine de la cybersécurité.
                    </li>
                    <li>
                      Contribuer à la rédaction des exigences de sécurité des
                      services Cloud et des composants liés à la consommation de
                      ces services.
                    </li>
                    <li>
                      Apporter une expertise sur les normes de sécurité et les
                      solutions de protection appliquées aux services Cloud.
                    </li>
                    <li>
                      Évaluer les mesures de sécurité en place et formuler des
                      recommandations d'amélioration en fonction des niveaux de
                      risques.
                    </li>
                    <li>
                      Veiller au respect des règles de sécurité en vigueur.{" "}
                    </li>
                    <li>
                      Maintenir une veille sur les normes de cybersécurité, les
                      solutions de protection, les menaces, les vulnérabilités
                      et les risques des systèmes d'information liés aux
                      écosystèmes Cloud.
                    </li>
                    <li>Participer aux cérémonies Agile des projets. </li>
                    <li>
                      Participer à des réunions et des échanges réguliers avec
                      le responsable et le Delivery Manager du service.
                    </li>
                  </ul>
                  <h2 className="text-base text-black font-semibold p-2">
                    Compétences techniques
                  </h2>
                  Les compétences techniques requises pour ce poste d'Architecte
                  Cloud Azure/AWS comprennent :
                </ol>

                <ol>
                  <ul class="pl-5 mt-2 space-y-1 list-disc list-inside p-3">
                    <li>
                      Une connaissance approfondie du cloud AWS et de ses
                      services tels que Lambda, Beanstalk, Athena, S3, EKS.
                    </li>
                    <li>
                      Une connaissance et une utilisation de la conteneurisation
                      avec Docker et Kubernetes (EKS).
                    </li>
                    <li>
                      Une connaissance des chaînes CI/CD et une utilisation
                      d'outils tels que GitLab, Nexus, Jenkins, Ansible,
                      Artifactory.
                    </li>
                    <li>
                      De bonnes connaissances en langages de script tels que
                      Shell, PowerShell et YAML. Une connaissance dans le
                      domaine de l'IaC (Infrastructure as Code) et l'utilisation
                      de Terraform sont souhaitables.
                    </li>
                    <li>
                      Une connaissance et une utilisation des outils Jira
                      (planner et tickets) et Confluence (documentation).
                    </li>
                  </ul>
                  Note : Ce poste d'Architecte Cloud Azure/AWS nécessite une
                  expertise approfondie en sécurité cloud et une maîtrise des
                  services AWS et Azure. Vous travaillerez en étroite
                  collaboration avec l'équipe pour définir et mettre en place
                  des politiques de sécurité, évaluer les mesures de sécurité
                  existantes et fournir des recommandations d'amélioration. Des
                  compétences avancées en conteneurisation, CI/CD, langages de
                  script et outils de gestion de projet sont également requises.
                </ol>
              </ol>

              <div className="time">
                <span className="me-4">
                  {" "}
                  <i className="fal fa-clock me-1 color-main"></i> Full-time{" "}
                </span>
                <span>
                  {" "}
                  <i className="fal fa-dollar-sign me-1 color-main p-4"></i> 40k
                  - $60k{" "}
                </span>
              </div>
              <span className="trend-mark">
                {" "}
                <i className="fas fa-bolt"></i>{" "}
              </span>
            </a>
          </div>

          {/* MISSION6 */}

          <div className="col-lg-4">
            <a href="#" className="position-card mb-4">
              <h5> Tech Lead Java</h5>

              <ol class="space-y-4  list-decimal list-inside dark:text-gray-600">
                <ol>
                  <h2 className="text-base text-black font-semibold p-2">
                    Responsabilités principales
                  </h2>
                  Bonjour, Dans le cadre d'une nouvelle mission chez notre
                  client, nous te proposons d'intervenir en tant que Tech Lead.
                  En tant que Tech Lead, tu auras les responsabilités suivantes:
                  <ul class="pl-5 mt-2 space-y-1 list-disc list-inside">
                    <li>
                      Concevoir et aider à la réalisation de solutions robustes
                      et efficientes.
                    </li>
                    <li>
                      Contribuer aux études et définir les spécifications
                      applicatives et techniques de la solution.
                    </li>
                    <li>
                      Participer à l'ensemble des rituels agiles tels que les
                      Daily meetings, l'Affinage, la Sprint review et la
                      Retrospective
                    </li>
                  </ul>
                </ol>

                <ol>
                  <h2 className="text-base text-black font-semibold p-2">
                    Exigences
                  </h2>
                  En tant que Tech Lead, tu devrais être au courant des
                  nouvelles tendances et opportunités technologiques et être
                  capable de les partager. Tu seras en relation avec les
                  différents pôles au sein du domaine Product Master Data, ainsi
                  qu'avec la plateforme Produit dans son ensemble. <br />
                  <h2 className="text-base text-black font-semibold p-2">
                    Profil et technologies
                  </h2>
                  Pour ce poste de Tech Lead, nous recherchons un candidat avec
                  les qualifications suivantes:
                  <ul class="pl-5 mt-2 space-y-1 list-disc list-inside">
                    <li>
                      Des compétences techniques avérées dans les domaines
                      suivants:
                      <ul class="pl-5 mt-2 space-y-1 list-disc list-inside">
                        <li>Java SpringBoot : Confirmé (Impératif) </li>
                        <li> Kafka : Confirmé (Impératif) Base de données</li>
                        <li>(SQL/NoSQL) : Confirmé (Impératif)</li>
                        <li>
                          ( Google Cloud) Platform (GCP) : Confirmé (Important)
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul class="pl-5 mt-2 space-y-1 list-disc list-inside p-2">
                    <li>
                      Une formation technique et une expérience professionnelle
                      d'au moins 10 ans dans le développement Java.
                    </li>
                    <li>
                      Une parfaite maîtrise du développement d'API, de
                      l'utilisation de Kafka et des modèles de bases de données.
                    </li>
                  </ul>
                  Note : En tant que Tech Lead Java, tu joueras un rôle clé dans
                  la conception et la réalisation de solutions techniques. Ta
                  maîtrise de Java, SpringBoot, Kafka, les bases de données et
                  GCP sera essentielle pour mener à bien cette mission. Tu
                  devras également avoir de solides compétences en leadership
                  technique et être capable de travailler de manière
                  collaborative avec différentes équipes.
                </ol>
              </ol>

              <div className="time">
                <span className="me-4">
                  {" "}
                  <i className="fal fa-clock me-1 color-main"></i> Full-time{" "}
                </span>
                <span>
                  {" "}
                  <i className="fal fa-dollar-sign me-1 color-main p-4"></i> 40k
                  - $60k{" "}
                </span>
              </div>
              <span className="trend-mark">
                {" "}
                <i className="fas fa-bolt"></i>{" "}
              </span>
            </a>
          </div>

          {/* MISSION7 */}

          <div className="col-lg-4">
            <a href="#" className="position-card mb-4">
              <h5> Développeur iOS</h5>

              <ol class="space-y-4  list-decimal list-inside dark:text-gray-600">
                <ol>
                  <h2 className="text-base text-black font-semibold p-2">
                    Expérience et compétences requises
                  </h2>
                  Nous recherchons un développeur iOS avec les qualifications
                  suivantes :
                  <ul class="pl-5 mt-2 space-y-1 list-disc list-inside">
                    <li>
                      Expérience professionnelle dans le développement iOS natif
                      en utilisant Swift (application 100% Swift v5).
                    </li>
                    <li>
                      Expérience sur un ou plusieurs projets similaires liés à
                      la consommation de vidéos et d'audios en streaming.
                    </li>
                    <li>
                      Maîtrise des bibliothèques tierces et des API REST/JSON.
                    </li>
                    <li>
                      Connaissance de plusieurs architectures, notamment
                      l'architecture MVVM et MVP.
                    </li>
                    <li>
                      Connaissance des concepts de la Clean Architecture et des
                      principes SOLID.
                    </li>
                    <li>
                      Veiller au respect des règles de sécurité en vigueur.
                    </li>
                    <li>
                      Connaissance appréciée des outils d'industrialisation tels
                      que Fastlane, Git, Jenkins et Firebase.
                    </li>
                    <li>Participer aux cérémonies Agile des projets. </li>
                    <li>
                      Participer à des réunions et des échanges réguliers avec
                      le responsable et le Delivery Manager du service.
                    </li>
                    <li>
                      Solides connaissances et expérience des tests unitaires.
                    </li>
                    <li>
                      Capacité à vulgariser les concepts techniques auprès des
                      équipes produit et éditoriales.
                    </li>
                    <li>
                      Connaissance des directives d'Apple. Connaissance de
                      UIKit.
                    </li>
                  </ul>
                </ol>

                <ol class=" p-2">
                  Note : Ce poste d'Architecte Cloud Azure/AWS nécessite une
                  expertise approfondie en sécurité cloud et une maîtrise des
                  services AWS et Azure. Vous travaillerez en étroite
                  collaboration avec l'équipe pour définir et mettre en place
                  des politiques de sécurité, évaluer les mesures de sécurité
                  existantes et fournir des recommandations d'amélioration. Des
                  compétences avancées en conteneurisation, CI/CD, langages de
                  script et outils de gestion de projet sont également requises.
                </ol>
              </ol>

              <div className="time">
                <span className="me-4">
                  {" "}
                  <i className="fal fa-clock me-1 color-main"></i> Full-time{" "}
                </span>
                <span>
                  {" "}
                  <i className="fal fa-dollar-sign me-1 color-main p-4"></i> 35k
                  - $60k{" "}
                </span>
              </div>
              <span className="trend-mark">
                {" "}
                <i className="fas fa-bolt"></i>{" "}
              </span>
            </a>
          </div>

          {/* <div className="d-flex justify-content-center">
            <button className="btn bg-blue5 sm-butn text-white hover-darkBlue mt-4 rounded-pill">
              <span>Voir tous les profils</span>
            </button>
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default ListProfile;
