import React from "react";
import ListProfile from "../../components/Recrutement/ListProfile";
import FormulaireProfile from "../../components/Recrutement/FormulaireProfile";

const Recrutement = () => {
  return (
    <div>
      <div id="preloader"> </div>
      <main className="careers-page style-5 pt-100">
        <ListProfile />
        <FormulaireProfile />
      </main>
    </div>
  );
};

export default Recrutement;
