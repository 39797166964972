import React from 'react'
import BlogSlider from '../../components/Blog/BlogSlider'
import PopularPost from '../../components/Blog/PopularPost'
import AllNews from '../../components/Blog/AllNews'

const Blog = () => {
  return (
    <div>
      <div id="preloader"> </div>
      <main className="blog-page style-5 color-4">
        <BlogSlider />
        <PopularPost />
        <AllNews />
      </main>
    </div>

  )
}


export default Blog