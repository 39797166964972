import React from "react";
import HeroHeader from "../../components/Accueil/Hero-header";
import PouquoiNousChoisir from "../../components/Accueil/PouquoiNousChoisir";
import NousChoisir from "../../components/Accueil/NousChoisir";

const Accueil = () => {
  return (
    <div>
      <div id="preloader"> </div>
      <HeroHeader />
      <PouquoiNousChoisir />
      <NousChoisir />
    </div>
  );
};

export default Accueil;
