import React from "react";

const RegieClassique = () => {
  return (
    <section className="about pt-0 pb-150 style-5">
      <div className="content">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-4 order-2 order-lg-0">
              <div className="section-head mb-30 style-5">
                <h2>
                  {" "}
                  Régie <span> classique </span>{" "}
                </h2>
              </div>
              <p>
                Profitez de l'expertise d'ADVSKILL pour sourcer rapidement des
                consultants expérimentés.
              </p>
              <div className="line-links">
                <a>
                  Ne cherchez plus le consultant IT idéal. Nous nous en
                  chargeons ! Maitriser vos coûts à nos côtés.
                </a>
              </div>
            </div>
            <div className="col-lg-8 order-0 order-lg-2">
              <div className="img main-img1">
                <img
                  src="assets/img/about/about_s5_1_1.png"
                  alt=""
                  className="sm-circle"
                />
                <img
                  src="assets/img/about/about_s5_1_2.png"
                  alt=""
                  className="img-body"
                />
                <img
                  src="assets/img/about/about_s5_1_3.png"
                  alt=""
                  className="card1"
                />
                <img
                  src="assets/img/about/about_s5_1_4.png"
                  alt=""
                  className="card2"
                />
                <img
                  src="assets/img/about/about_s5_1_5.png"
                  alt=""
                  className="lg-circle"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-8">
              <div className="img main-img2">
                <img src="assets/img/about/Nos-partenaires.png" alt="" />
                <img
                  src="assets/img/about/about_s5_2_1.png"
                  alt=""
                  className="img-body"
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="section-head mb-30 style-5">
                <h2>
                  {" "}
                  Régie <span> déportée </span>{" "}
                </h2>
              </div>
              <p>
                Avec la régie déportée imaginée pour sourcer à l'étranger plus
                de ressources, ADVSKILL met à votre disposition des consultants
                expérimentés ayant une culture francophone dans un environnement
                francophone avec l'avantage de TJM très compétitifs. Cela dans
                le but de baisser et/ou optimiser les coûts de vos projets IT.
              </p>
              <div className="line-links">
                <a>
                  Les consultants sont managés par un chef de projet assurant la
                  qualité des échanges entre Votre équipe et la notre.
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RegieClassique;
