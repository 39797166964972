import React from 'react';
import { Routes, Route } from "react-router-dom";

import Blog from "../pages/blog/Blog";
import Contact from "../pages/contact/Contact";
import Accueil from "../pages/accueil/Accueil";
import AproposDeNous from "../pages/apropos/AproposDeNous";
import NotreExpertises from "../pages/notreexpertises/NotreExpertises";
import NosValeurs from "../components/NosValeurs/NosValeurs";
import Recrutement from "../pages/recrutement/Recrutement";
import Mention from "../pages/mention-legales/Mention";

import MaintenancePage from "../pages/errorpages/maintenance";

const Routers = () => {
  return (
    <Routes>
      <Route path="/" element={<Accueil to="/" />} />
      <Route path="/qui-sommes-nous" element={<AproposDeNous />} />
      <Route path="/notre-expertise" element={<NotreExpertises />} />
      <Route path="/nos-valeurs" element={<NosValeurs />} />
      <Route path="/recrutement" element={<Recrutement />} />
      <Route path="/mentions-legales" element={<Mention />} />
      <Route path="/actualite" element={<Blog />} />
      <Route path="/contacts" element={<Contact />} />

      <Route path="*" element={<MaintenancePage />} />
    </Routes>
  );
};

export default Routers