import React from 'react'

const BlogSlider = () => {
  return (
    <section className="blog-slider pt-50 pb-50 style-1">
            <div className="container">
                <div className="section-head text-center mb-60 style-4">
                    <h2 className="mb-20"> Our <span> Journal </span> </h2>
                    <div className="text color-666">Get the latest articles from our journal, writing, discuss and share</div>
                </div>
                <div className="blog-details-slider">
                    <div className="swiper-container">
                        <div className="swiper-wrapper">
                            <div className="swiper-slide">
                                <div className="content-card">
                                    <div className="img overlay">
                                        <img src="assets/img/blog/s_blog.png" alt=""/>
                                    </div>
                                    <div className="info">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="cont">
                                                    <small className="date small mb-20"> <a href="#" className="text-uppercase border-end brd-gray pe-3 me-3"> News </a> <i className="far fa-clock me-1"></i> Posted on <a href="#">3 Days ago</a> </small>
                                                    <h2 className="title">
                                                        <a href="page-single-post-5.html">Solutions For Big Data Issue, Expert Perspective</a>
                                                    </h2>
                                                    <p className="fs-13px mt-10 text-light text-info">If there’s one way that wireless technology has changed the way we work, it’s that will everyone is now connected [...]</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="content-card">
                                    <div className="img overlay">
                                        <img src="assets/img/blog/1.jpeg" alt=""/>
                                    </div>
                                    <div className="info">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="cont">
                                                    <small className="date small mb-20"> <a href="#" className="text-uppercase border-end brd-gray pe-3 me-3"> News </a> <i className="far fa-clock me-1"></i> Posted on <a href="#">3 Days ago</a> </small>
                                                    <h2 className="title">
                                                        <a href="page-single-post-5.html">Solutions For Big Data Issue, Expert Perspective</a>
                                                    </h2>
                                                    <p className="fs-13px mt-10 text-light text-info">If there’s one way that wireless technology has changed the way we work, it’s that will everyone is now connected [...]</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="content-card">
                                    <div className="img overlay">
                                        <img src="assets/img/blog/2.jpeg" alt=""/>
                                    </div>
                                    <div className="info">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="cont">
                                                    <small className="date small mb-20"> <a href="#" className="text-uppercase border-end brd-gray pe-3 me-3"> News </a> <i className="far fa-clock me-1"></i> Posted on <a href="#">3 Days ago</a> </small>
                                                    <h2 className="title">
                                                        <a href="page-single-post-5.html">Solutions For Big Data Issue, Expert Perspective</a>
                                                    </h2>
                                                    <p className="fs-13px mt-10 text-light text-info">If there’s one way that wireless technology has changed the way we work, it’s that will everyone is now connected [...]</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <!-- ====== pagination ====== --> */}
                    <div className="swiper-pagination"></div>
                    {/* <!-- ====== arrows ====== --> */}
                    <div className="swiper-button-next"></div>
                    <div className="swiper-button-prev"></div>
                </div>
            </div>
        </section>
  )
}

export default BlogSlider